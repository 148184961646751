import {generatedApi as api} from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({url: `/api/login`, method: 'POST', body: queryArg.loginRequest}),
    }),
    uploadFileToWallet: build.mutation<UploadFileToWalletApiResponse, UploadFileToWalletApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/${queryArg.fileType}/upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    saveWallet: build.mutation<SaveWalletApiResponse, SaveWalletApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/update`,
        method: 'POST',
        body: queryArg.saveWalletRequest,
      }),
    }),
    getParticipant: build.query<GetParticipantApiResponse, GetParticipantApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/sales-person/${queryArg.packet}`,
      }),
    }),
    updateParticipant: build.mutation<UpdateParticipantApiResponse, UpdateParticipantApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/sales-person/${queryArg.packet}`,
        method: 'POST',
        body: queryArg.saveParticipantRequest,
      }),
    }),
    deleteParticipant: build.mutation<DeleteParticipantApiResponse, DeleteParticipantApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/sales-person/${queryArg.packet}`,
        method: 'DELETE',
      }),
    }),
    uploadParticipants: build.mutation<UploadParticipantsApiResponse, UploadParticipantsApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/sales-person/upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    saveOrganizationWallet: build.mutation<
      SaveOrganizationWalletApiResponse,
      SaveOrganizationWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/organization/update`,
        method: 'POST',
        body: queryArg.saveCouponWalletRequest,
      }),
    }),
    updateOrganizationWallet: build.mutation<
      UpdateOrganizationWalletApiResponse,
      UpdateOrganizationWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/wallet/organization/create`,
        method: 'POST',
        body: queryArg.saveCouponWalletRequest,
      }),
    }),
    createWallet: build.mutation<CreateWalletApiResponse, CreateWalletApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/create`,
        method: 'POST',
        body: queryArg.saveWalletRequest,
      }),
    }),
    getParticipantRemainEmail: build.query<
      GetParticipantRemainEmailApiResponse,
      GetParticipantRemainEmailApiArg
    >({
      query: () => ({url: `/api/internal/settings/email/participant/remain`}),
    }),
    updateParticipantRemainEmail: build.mutation<
      UpdateParticipantRemainEmailApiResponse,
      UpdateParticipantRemainEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/settings/email/participant/remain`,
        method: 'POST',
        params: queryArg.emailTemplate,
      }),
    }),
    getParticipantFinishedEmail: build.query<
      GetParticipantFinishedEmailApiResponse,
      GetParticipantFinishedEmailApiArg
    >({
      query: () => ({url: `/api/internal/settings/email/participant/finished`}),
    }),
    updateParticipantFinishedEmail: build.mutation<
      UpdateParticipantFinishedEmailApiResponse,
      UpdateParticipantFinishedEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/settings/email/participant/finished`,
        method: 'POST',
        params: queryArg.emailTemplate,
      }),
    }),
    getPass: build.query<GetPassApiResponse, GetPassApiArg>({
      query: (queryArg) => ({url: `/api/internal/pass/${queryArg.passId}`}),
    }),
    updatePass: build.mutation<UpdatePassApiResponse, UpdatePassApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/pass/${queryArg.passId}`,
        method: 'POST',
        body: queryArg.savePassRequest,
      }),
    }),
    deletePass: build.mutation<DeletePassApiResponse, DeletePassApiArg>({
      query: (queryArg) => ({url: `/api/internal/pass/${queryArg.passId}`, method: 'DELETE'}),
    }),
    regeneratePass: build.mutation<RegeneratePassApiResponse, RegeneratePassApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/pass/${queryArg.passId}/regenerate`,
        method: 'POST',
      }),
    }),
    regenerateWalletPasses: build.mutation<
      RegenerateWalletPassesApiResponse,
      RegenerateWalletPassesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/pass/wallet/${queryArg.walletId}/regenerate`,
        method: 'POST',
      }),
    }),
    exportParticipants: build.mutation<ExportParticipantsApiResponse, ExportParticipantsApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.walletId}/sales-person/export/excel`,
        method: 'POST',
      }),
    }),
    deleteQrCoupons: build.mutation<DeleteQrCouponsApiResponse, DeleteQrCouponsApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.walletId}/qr/invalidate`,
        method: 'POST',
      }),
    }),
    generateQrCodes: build.mutation<GenerateQrCodesApiResponse, GenerateQrCodesApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.walletId}/qr/generate`,
        method: 'POST',
        body: queryArg.generateQrRequest,
      }),
    }),
    exportToExcel: build.mutation<ExportToExcelApiResponse, ExportToExcelApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.walletId}/export/excel`,
        method: 'POST',
        params: {
          status: queryArg.status,
        },
      }),
    }),
    resendPass: build.mutation<ResendPassApiResponse, ResendPassApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.passId}/resend`,
        method: 'POST',
      }),
    }),
    invalidatePass: build.mutation<InvalidatePassApiResponse, InvalidatePassApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/action/${queryArg.passId}/invalidate`,
        method: 'POST',
      }),
    }),
    getLastVersionOfPass: build.mutation<
      GetLastVersionOfPassApiResponse,
      GetLastVersionOfPassApiArg
    >({
      query: (queryArg) => ({
        url: `/api/google/callback`,
        method: 'POST',
        body: queryArg.googleCallbackRequest,
      }),
    }),
    registerCustomerByQr: build.mutation<
      RegisterCustomerByQrApiResponse,
      RegisterCustomerByQrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/customer/${queryArg.token}/register`,
        method: 'POST',
        body: queryArg.registerCustomerRequest,
      }),
    }),
    registerCustomer: build.mutation<RegisterCustomerApiResponse, RegisterCustomerApiArg>({
      query: (queryArg) => ({
        url: `/api/customer/organization/${queryArg.walletId}/register`,
        method: 'POST',
        body: queryArg.registerCustomerRequest,
      }),
    }),
    logErrors: build.mutation<LogErrorsApiResponse, LogErrorsApiArg>({
      query: (queryArg) => ({
        url: `/api/apple/v1/log`,
        method: 'POST',
        body: queryArg.appleLogErrors,
      }),
    }),
    registerDevice: build.mutation<RegisterDeviceApiResponse, RegisterDeviceApiArg>({
      query: (queryArg) => ({
        url: `/api/apple/v1/devices/${queryArg.deviceId}/registrations/${queryArg.passTypeId}/${queryArg.serialNumber}`,
        method: 'POST',
        body: queryArg.appleRegisterDeviceRequest,
        headers: {
          Authorization: queryArg.authorization,
        },
      }),
    }),
    unregisterDevice: build.mutation<UnregisterDeviceApiResponse, UnregisterDeviceApiArg>({
      query: (queryArg) => ({
        url: `/api/apple/v1/devices/${queryArg.deviceId}/registrations/${queryArg.passTypeId}/${queryArg.serialNumber}`,
        method: 'DELETE',
        headers: {
          Authorization: queryArg.authorization,
        },
      }),
    }),
    getWallet: build.query<GetWalletApiResponse, GetWalletApiArg>({
      query: (queryArg) => ({url: `/api/internal/wallet/${queryArg.walletId}`}),
    }),
    deleteWallet: build.mutation<DeleteWalletApiResponse, DeleteWalletApiArg>({
      query: (queryArg) => ({url: `/api/internal/wallet/${queryArg.walletId}`, method: 'DELETE'}),
    }),
    getParticipantsPage: build.query<GetParticipantsPageApiResponse, GetParticipantsPageApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/sales-person`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sortBy: queryArg.sortBy,
          direction: queryArg.direction,
        },
      }),
    }),
    walletPasses: build.query<WalletPassesApiResponse, WalletPassesApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/passes`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          status: queryArg.status,
        },
      }),
    }),
    getWalletModel: build.query<GetWalletModelApiResponse, GetWalletModelApiArg>({
      query: (queryArg) => ({url: `/api/internal/wallet/${queryArg.walletId}/model`}),
    }),
    getWallets: build.query<GetWalletsApiResponse, GetWalletsApiArg>({
      query: (queryArg) => ({
        url: `/api/internal/wallet/list`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    walletSalesPerson: build.query<WalletSalesPersonApiResponse, WalletSalesPersonApiArg>({
      query: (queryArg) => ({
        url: `/api/customer/${queryArg.walletId}/sales-person`,
        params: {
          query: queryArg.query,
        },
      }),
    }),
    getTokenInfo: build.query<GetTokenInfoApiResponse, GetTokenInfoApiArg>({
      query: (queryArg) => ({url: `/api/customer/${queryArg.token}`}),
    }),
    organizationList: build.query<OrganizationListApiResponse, OrganizationListApiArg>({
      query: (queryArg) => ({
        url: `/api/customer/organization`,
        params: {
          nameQuery: queryArg.nameQuery,
        },
      }),
    }),
    getOrganizationInfo: build.query<GetOrganizationInfoApiResponse, GetOrganizationInfoApiArg>({
      query: (queryArg) => ({url: `/api/customer/organization/${queryArg.walletId}`}),
    }),
    redirectToPassUrl: build.query<RedirectToPassUrlApiResponse, RedirectToPassUrlApiArg>({
      query: (queryArg) => ({
        url: `/api/customer/download/${queryArg.passId}`,
        headers: {
          'User-Agent': queryArg['User-Agent'],
        },
      }),
    }),
    getLastVersionOfPass1: build.query<
      GetLastVersionOfPass1ApiResponse,
      GetLastVersionOfPass1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/apple/v1/passes/${queryArg.passTypeId}/${queryArg.serialNumber}`,
        headers: {
          Authorization: queryArg.authorization,
        },
      }),
    }),
    getDeviceRegistered: build.query<GetDeviceRegisteredApiResponse, GetDeviceRegisteredApiArg>({
      query: (queryArg) => ({
        url: `/api/apple/v1/devices/${queryArg.deviceId}/registrations/${queryArg.passTypeId}`,
        params: {
          passesUpdatedSince: queryArg.passesUpdatedSince,
        },
      }),
    }),
    deleteFileFromWallet: build.mutation<
      DeleteFileFromWalletApiResponse,
      DeleteFileFromWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/internal/wallet/${queryArg.walletId}/${queryArg.fileType}/delete`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
})
export {injectedRtkApi as backendGeneratedApi}
export type LoginApiResponse = unknown
export type LoginApiArg = {
  loginRequest: LoginRequest
}
export type UploadFileToWalletApiResponse = unknown
export type UploadFileToWalletApiArg = {
  /** Wallet ID */
  walletId: string
  /** File type */
  fileType: 'REGISTRATION' | 'ICON' | 'THUMBNAIL' | 'LOGO'
  body: {
    file: Blob
  }
}
export type SaveWalletApiResponse = /** status 200 OK */ Wallet
export type SaveWalletApiArg = {
  /** Wallet ID */
  walletId: string
  saveWalletRequest: SaveWalletRequest
}
export type GetParticipantApiResponse = /** status 200 OK */ Participant
export type GetParticipantApiArg = {
  /** Wallet ID */
  walletId: string
  /** Package Number */
  packet: string
}
export type UpdateParticipantApiResponse = /** status 200 OK */ Participant
export type UpdateParticipantApiArg = {
  /** Wallet ID */
  walletId: string
  /** Package Number */
  packet: string
  saveParticipantRequest: SaveParticipantRequest
}
export type DeleteParticipantApiResponse = unknown
export type DeleteParticipantApiArg = {
  /** Wallet ID */
  walletId: string
  /** Package Number */
  packet: string
}
export type UploadParticipantsApiResponse = unknown
export type UploadParticipantsApiArg = {
  /** Wallet ID */
  walletId: string
  body: {
    file: Blob
  }
}
export type SaveOrganizationWalletApiResponse = /** status 200 OK */ WalletAdminDto
export type SaveOrganizationWalletApiArg = {
  /** Wallet ID */
  walletId: string
  saveCouponWalletRequest: SaveCouponWalletRequest
}
export type UpdateOrganizationWalletApiResponse = /** status 200 OK */ WalletAdminDto
export type UpdateOrganizationWalletApiArg = {
  saveCouponWalletRequest: SaveCouponWalletRequest
}
export type CreateWalletApiResponse = /** status 200 OK */ Wallet
export type CreateWalletApiArg = {
  saveWalletRequest: SaveWalletRequest
}
export type GetParticipantRemainEmailApiResponse = /** status 200 OK */ EmailTemplate
export type GetParticipantRemainEmailApiArg = void
export type UpdateParticipantRemainEmailApiResponse = unknown
export type UpdateParticipantRemainEmailApiArg = {
  emailTemplate: EmailTemplate
}
export type GetParticipantFinishedEmailApiResponse = /** status 200 OK */ EmailTemplate
export type GetParticipantFinishedEmailApiArg = void
export type UpdateParticipantFinishedEmailApiResponse = unknown
export type UpdateParticipantFinishedEmailApiArg = {
  emailTemplate: EmailTemplate
}
export type GetPassApiResponse = /** status 200 OK */ Pass
export type GetPassApiArg = {
  /** Pass ID */
  passId: string
}
export type UpdatePassApiResponse = /** status 200 OK */ Pass
export type UpdatePassApiArg = {
  /** Pass ID */
  passId: string
  savePassRequest: SavePassRequest
}
export type DeletePassApiResponse = unknown
export type DeletePassApiArg = {
  /** Pass ID */
  passId: string
}
export type RegeneratePassApiResponse = /** status 200 OK */ string
export type RegeneratePassApiArg = {
  /** Pass ID */
  passId: string
}
export type RegenerateWalletPassesApiResponse = unknown
export type RegenerateWalletPassesApiArg = {
  /** Wallet ID */
  walletId: string
}
export type ExportParticipantsApiResponse = /** status 200 OK */ UrlResponse
export type ExportParticipantsApiArg = {
  /** Wallet ID */
  walletId: string
}
export type DeleteQrCouponsApiResponse = unknown
export type DeleteQrCouponsApiArg = {
  /** Wallet ID */
  walletId: string
}
export type GenerateQrCodesApiResponse = /** status 200 OK */ UrlResponse
export type GenerateQrCodesApiArg = {
  /** Wallet ID */
  walletId: string
  generateQrRequest: GenerateQrRequest
}
export type ExportToExcelApiResponse = /** status 200 OK */ UrlResponse
export type ExportToExcelApiArg = {
  /** Wallet ID */
  walletId: string
  /** Pass status */
  status?: 'ERROR' | 'QR' | 'ACTIVE' | 'INSTALLED'
}
export type ResendPassApiResponse = unknown
export type ResendPassApiArg = {
  /** Pass ID */
  passId: string
}
export type InvalidatePassApiResponse = unknown
export type InvalidatePassApiArg = {
  /** Pass ID */
  passId: string
}
export type GetLastVersionOfPassApiResponse = /** status 200 OK */ string
export type GetLastVersionOfPassApiArg = {
  googleCallbackRequest: GoogleCallbackRequest
}
export type RegisterCustomerByQrApiResponse = /** status 200 OK */ RegisterCustomerResponse
export type RegisterCustomerByQrApiArg = {
  /** Unique token from QR code */
  token: string
  registerCustomerRequest: RegisterCustomerRequest
}
export type RegisterCustomerApiResponse = /** status 200 OK */ RegisterCustomerResponse
export type RegisterCustomerApiArg = {
  /** Wallet (organization) ID */
  walletId: string
  registerCustomerRequest: RegisterCustomerRequest
}
export type LogErrorsApiResponse = /** status 200 OK */ string
export type LogErrorsApiArg = {
  appleLogErrors: AppleLogErrors
}
export type RegisterDeviceApiResponse = /** status 200 OK */ string
export type RegisterDeviceApiArg = {
  deviceId: string
  passTypeId: string
  serialNumber: string
  authorization: string
  appleRegisterDeviceRequest: AppleRegisterDeviceRequest
}
export type UnregisterDeviceApiResponse = /** status 200 OK */ string
export type UnregisterDeviceApiArg = {
  deviceId: string
  passTypeId: string
  serialNumber: string
  authorization: string
}
export type GetWalletApiResponse = /** status 200 OK */ WalletAdminDto
export type GetWalletApiArg = {
  /** Wallet ID */
  walletId: string
}
export type DeleteWalletApiResponse = unknown
export type DeleteWalletApiArg = {
  /** Wallet ID */
  walletId: string
}
export type GetParticipantsPageApiResponse = /** status 200 OK */ PageParticipant
export type GetParticipantsPageApiArg = {
  /** Wallet ID */
  walletId: string
  /** Page number */
  page?: number
  /** Page size */
  size?: number
  /** Sort column */
  sortBy?: 'totalDonations' | 'firstName' | 'lastName' | 'packet'
  /** Sort direction */
  direction?: 'ASC' | 'DESC'
}
export type WalletPassesApiResponse = /** status 200 OK */ PagePassAdminDto
export type WalletPassesApiArg = {
  /** Wallet ID */
  walletId: string
  /** Page number */
  page?: number
  /** Page size */
  size?: number
  /** Pass status */
  status?: 'ERROR' | 'QR' | 'ACTIVE' | 'INSTALLED'
}
export type GetWalletModelApiResponse = /** status 200 OK */ Wallet
export type GetWalletModelApiArg = {
  /** Wallet ID */
  walletId: string
}
export type GetWalletsApiResponse = /** status 200 OK */ PageWalletAdminDto
export type GetWalletsApiArg = {
  /** Page number */
  page?: number
  /** Page size */
  size?: number
}
export type WalletSalesPersonApiResponse = /** status 200 OK */ ParticipantView[]
export type WalletSalesPersonApiArg = {
  /** Wallet ID */
  walletId: string
  /** Query string */
  query?: string
}
export type GetTokenInfoApiResponse = /** status 200 OK */ OrganizationInfoDto
export type GetTokenInfoApiArg = {
  /** Unique token from QR code */
  token: string
}
export type OrganizationListApiResponse = /** status 200 OK */ WalletSignupView[]
export type OrganizationListApiArg = {
  /** Query string */
  nameQuery?: string
}
export type GetOrganizationInfoApiResponse = /** status 200 OK */ OrganizationInfoDto
export type GetOrganizationInfoApiArg = {
  /** Wallet (organization) ID */
  walletId: string
}
export type RedirectToPassUrlApiResponse = unknown
export type RedirectToPassUrlApiArg = {
  /** Pass ID from the link */
  passId: string
  'User-Agent': string
}
export type GetLastVersionOfPass1ApiResponse = /** status 200 OK */ string
export type GetLastVersionOfPass1ApiArg = {
  passTypeId: string
  serialNumber: string
  authorization: string
}
export type GetDeviceRegisteredApiResponse = /** status 200 OK */ AppleRegisteredPassesResponse
export type GetDeviceRegisteredApiArg = {
  deviceId: string
  passTypeId: string
  passesUpdatedSince?: string
}
export type DeleteFileFromWalletApiResponse = unknown
export type DeleteFileFromWalletApiArg = {
  /** Wallet ID */
  walletId: string
  /** File type */
  fileType: 'REGISTRATION' | 'ICON' | 'THUMBNAIL' | 'LOGO'
}
export type ErrorMessageDto = {
  message?: string
}
export type LoginRequest = {
  /** Login */
  login: string
  /** Password */
  password: string
}
export type PassField = {
  /** Field index on the line */
  index?: number
  /** Field key to manager it */
  key?: string
  /** Field label, visible on the pass */
  label?: string
  /** Field value, visible on the pass */
  value?: string
  /** Field is a link or not */
  link?: boolean
  /** Field can be changed */
  canChange?: boolean
  /** Alignment on the line */
  alignment?: 'LEFT' | 'CENTER' | 'RIGHT' | 'NATURAL'
  /** Push message, that user will see after field changing */
  changeMessage?: string
}
export type GoogleWalletConfig = {
  /** Generate google config or not */
  active?: boolean
  /** Google pass type. GENERIC is more flexible, use by default. */
  passType: 'OFFER' | 'EVENTTICKET' | 'FLIGHT' | 'GIFTCARD' | 'LOYALTY' | 'TRANSIT' | 'GENERIC'
  /** Unique merchant account ID */
  issuerId: string
  /** Unique google wallet UUID. Required for existed wallet */
  classId?: string
  /** Logo image of the pass */
  logoImageUrl: string
  /** Main image of the pass */
  mainImageUrl: string
  topField?: PassField
  /** Pass fields configuration on the front first row */
  firstRowFields?: PassField[]
  /** Pass fields configuration on the front second row */
  secondRowFields?: PassField[]
}
export type Location = {
  /** Latitude */
  latitude?: number
  /** Longitude */
  longitude?: number
  /** Notification text */
  relevantText?: string
}
export type AppleWalletConfig = {
  /** Generate google config or not */
  active?: boolean
  /** Google pass type. STORE_CARD or GENERIC is more flexible, use by default. */
  passType: 'COUPON' | 'GENERIC' | 'EVENT' | 'STORE_CARD'
  /** Unique for each wallet app pass type ID. */
  passTypeIdentifier: string
  /** Unique 10-character string generated by Apple that’s assigned to your team */
  teamIdentifier: string
  topField?: PassField
  primaryField?: PassField
  /** Pass fields configuration on the front first row */
  firstRowFields?: PassField[]
  /** Pass fields configuration on the front second row */
  secondRowFields?: PassField[]
  /** Pass locations to notify customers, distance is 100 meters. */
  locations?: Location[]
}
export type Partner = {
  /** Partner name */
  name: string
  /** Partner description */
  description: string
} | null
export type Participant = {
  /** Packet Number */
  packet: string
  /** First Name */
  firstName: string
  /** LastName Name */
  lastName: string
  /** Email */
  email?: string
  /** Group */
  group?: string
  /** Total Donations */
  totalDonations: number
  /** Donations goal */
  goal?: number
  name?: string
}
export type Wallet = {
  id?: string
  displayed?: boolean
  generatePasses?: boolean
  name: string
  address: string
  description: string
  expirationDate?: string
  registrationMessage?: string
  registrationSuccessMessage?: string
  registrationErrorMessage?: string
  status?: 'DRAFT' | 'READY'
  expiredThumbnailImageUrl?: string
  registrationImageUrl?: string
  thumbnailImageUrl?: string
  iconImageUrl?: string
  logoImageUrl?: string
  googleConfig: GoogleWalletConfig
  appleConfig: AppleWalletConfig
  programName: string
  backgroundColor: string
  foregroundColor: string
  partners: Partner[]
  amounts?: number[]
  salesPersons?: Participant[]
  emailBodyPassPayment?: string
  emailBodyReceipt?: string
}
export type SaveWalletRequest = {
  /** Organization name */
  name: string
  /** Organization address */
  address?: string | null
  /** Registration message */
  registrationMessage?: string | null
  /** Registration success message */
  registrationSuccessMessage?: string | null
  /** Registration error message */
  registrationErrorMessage?: string | null
  /** Organization description */
  description?: string | null
  /** Wallet status */
  status?: 'DRAFT' | 'READY'
  /** Expiration date */
  expirationDate?: string | null
  thumbnailImageUrl?: string
  iconImageUrl?: string
  logoImageUrl?: string
  googleConfig: GoogleWalletConfig
  appleConfig: AppleWalletConfig
  /** Pass back fields configuration */
  backFields?: PassField[]
  /** Loyalty program name, for pass description. Common field */
  programName: string
  /** Background color, in #000000 format */
  backgroundColor: string
  /** Foreground color, in #000000 format */
  foregroundColor: string
  /** Partners list */
  partners?: Partner[] | null
  /** Donation amounts */
  amounts?: (number | null)[] | null
  /** Organization displayed on the signup page */
  displayed?: boolean
  /** Organization generate digital passes */
  generatePasses?: boolean
  /** Email template with generated pass */
  emailBodyPassPayment?: string
  /** Email template without generated pass (receipt) */
  emailBodyReceipt?: string
}
export type SaveParticipantRequest = {
  /** First Name */
  firstName: string
  /** LastName Name */
  lastName: string
  /** Email */
  email?: string
  /** Group */
  group?: string
  /** Goal */
  goal?: number
}
export type WalletAdminDto = {
  id?: string
  displayed?: boolean
  generatePasses?: boolean
  name?: string
  address?: string
  description?: string
  status?: 'DRAFT' | 'READY'
  expirationDate?: string
  thumbnailImageUrl?: string
  iconImageUrl?: string
  logoImageUrl?: string
  registrationImageUrl?: string
  programName?: string
  backgroundColor?: string
  foregroundColor?: string
  partners?: Partner[]
  amounts?: number[]
  registrationMessage?: string
  registrationSuccessMessage?: string
  registrationErrorMessage?: string
  countQr?: number
  countReady?: number
  emailBodyPassPayment?: string
  emailBodyReceipt?: string
}
export type SaveCouponWalletRequest = {
  /** Organization name */
  name: string
  /** Organization address */
  address?: string | null
  /** Organization description */
  description?: string | null
  /** Expiration date */
  expirationDate?: string | null
  /** Registration message */
  registrationMessage?: string | null
  /** Registration success message */
  registrationSuccessMessage?: string | null
  /** Registration error message */
  registrationErrorMessage?: string | null
  /** Coupon program name, for pass description */
  programName: string
  /** Background color, in #000000 format */
  backgroundColor: string
  /** Foreground color, in #000000 format */
  foregroundColor: string
  /** Partners list */
  partners?: Partner[] | null
  /** Donation amounts */
  amounts?: (number | null)[] | null
  /** Organization displayed on the signup page */
  displayed?: boolean
  /** Organization generate digital passes */
  generatePasses?: boolean
  /** Email template with generated pass */
  emailBodyPassPayment?: string
  /** Email template without generated pass (receipt) */
  emailBodyReceipt?: string
}
export type EmailTemplate = {
  /** Subject */
  subject?: string
  /** Body */
  body?: string
}
export type Customer = {
  /** Fist name */
  firstName: string
  /** Last name */
  lastName: string
  /** Customer telephone */
  telephone: string
  /** Customer email */
  email: string
  /** Sales Person */
  salesPerson?: string
  /** Packet # */
  packet?: string
  /** Zip code */
  zipCode?: string
  name?: string
}
export type AppleDevice = {
  id?: string
  pushToken?: string
  registeredAt?: string
}
export type ApplePassConfig = {
  authToken?: string
  webServiceUrl?: string
  passUrl?: string
  devices?: AppleDevice[]
}
export type GoogleDevice = {
  eventId?: string
  registeredAt?: string
}
export type GooglePassConfig = {
  objectId?: string
  classId?: string
  passUrl?: string
  devices?: GoogleDevice[]
}
export type ApplePassDto = {
  topField?: PassField
  primaryField?: PassField
  secondaryFields?: PassField[]
  auxiliaryFields?: PassField[]
  backFields?: PassField[]
}
export type GooglePassDto = {
  topField?: PassField
  textFields?: PassField[]
  linkFields?: PassField[]
}
export type Pass = {
  id?: string
  wallet?: string
  token?: string
  status?: 'ERROR' | 'QR' | 'ACTIVE' | 'INSTALLED'
  error?: string
  barcode?: string
  customer?: Customer
  expirationDate?: string
  linkExpirationDate?: string
  appleConfig?: ApplePassConfig
  googleConfig?: GooglePassConfig
  appleData?: ApplePassDto
  googleData?: GooglePassDto
  createdAt?: string
  updatedAt?: string
  paymentProvider?: string
  paymentResponse?: string
  paymentStatus?: string
  paidAmount?: number
}
export type SavePassRequest = {
  /** Wallet ID */
  walletId: string
  /** Fields values */
  fieldsValues: {
    [key: string]: string
  }
  customer?: Customer
}
export type UrlResponse = {
  /** Url with file */
  url?: string
}
export type GenerateQrRequest = {
  /** Coupons count */
  count: number
  /** Expiration date */
  expirationDate: string
}
export type GoogleCallbackRequest = {
  signedMessage?: string
}
export type RegisterCustomerResponse = {
  /** Apple url */
  appleUrl?: string
  /** Google url */
  googleUrl?: string
  /** Success message */
  successMessage?: string
}
export type RegisterCustomerRequest = {
  /** First name */
  firstName: string
  /** Last name */
  lastName: string
  /** Telephone number */
  telephone: string
  /** Email */
  email: string
  /** Zip Code */
  zipCode: string
  /** Packet # */
  packet?: string | null
  /** Sales person */
  salesPerson?: string | null
  /** Payment token, for API with payment form */
  paymentToken?: string | null
  /** Payment amount */
  amount?: number | null
}
export type AppleLogErrors = {
  logs?: string[]
}
export type AppleRegisterDeviceRequest = {
  pushToken?: string
}
export type SortObject = {
  empty?: boolean
  sorted?: boolean
  unsorted?: boolean
}
export type PageableObject = {
  offset?: number
  sort?: SortObject
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  unpaged?: boolean
}
export type PageParticipant = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Participant[]
  number?: number
  sort?: SortObject
  numberOfElements?: number
  pageable?: PageableObject
  first?: boolean
  last?: boolean
  empty?: boolean
}
export type ParticipantAdminDto = {
  /** Name */
  name: string
  /** Donation Amoun */
  donationAmount: number
}
export type PassAdminDto = {
  number?: number
  id?: string
  wallet?: string
  token?: string
  status?: 'ERROR' | 'QR' | 'ACTIVE' | 'INSTALLED'
  customer?: Customer
  participant?: ParticipantAdminDto
  expirationDate?: string
  createdAt?: string
  updatedAt?: string
}
export type PagePassAdminDto = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: PassAdminDto[]
  number?: number
  sort?: SortObject
  numberOfElements?: number
  pageable?: PageableObject
  first?: boolean
  last?: boolean
  empty?: boolean
}
export type PageWalletAdminDto = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: WalletAdminDto[]
  number?: number
  sort?: SortObject
  numberOfElements?: number
  pageable?: PageableObject
  first?: boolean
  last?: boolean
  empty?: boolean
}
export type ParticipantView = {
  /** ID */
  id: number
  /** Name */
  name: string
  /** Packet name */
  packet: string
  /** Goal */
  goal?: number
}
export type OrganizationInfoDto = {
  /** Internal organization ID */
  id?: string
  /** Organization name */
  name?: string
  /** Background color, in #000000 format */
  backgroundColor: string
  /** Foreground color, in #000000 format */
  foregroundColor: string
  /** Logo image */
  logoImageUrl: string
  /** Registration image below submit button */
  registrationImageUrl?: string
  /** Registration message */
  registrationMessage?: string
  /** Donation amounts */
  amounts?: number[]
}
export type WalletSignupView = {
  /** ID */
  id: string
  /** Wallet (organization) name */
  name: string
  /** Logo image */
  logoImageUrl: string
  /** Registration image below submit button */
  registrationImageUrl?: string
}
export type AppleRegisteredPassesResponse = {
  serialNumbers?: string[]
  lastUpdated?: string
}
