import {backendGeneratedApi} from './backendGeneratedApi'

export const coreApi = backendGeneratedApi.enhanceEndpoints({
  addTagTypes: [
    'Companies',
    'Company',
    'Coupons',
    'Participants',
    'Coupon',
    'RemainEmail',
    'FinishedEmail',
  ],
  endpoints: {
    getWallets: {
      providesTags: ['Companies'],
    },
    updateOrganizationWallet: {
      invalidatesTags: ['Companies'],
    },
    saveOrganizationWallet: {
      invalidatesTags: (_, __, arg) => [{type: 'Company', id: arg.walletId}, 'Companies'],
    },
    getWallet: {
      providesTags: (result) => (result ? [{type: 'Company', id: result.id}] : []),
    },
    uploadFileToWallet: {
      invalidatesTags: (_, __, arg) => [{type: 'Company', id: arg.walletId}, 'Companies'],
    },
    deleteFileFromWallet: {
      invalidatesTags: (_, __, arg) => [{type: 'Company', id: arg.walletId}, 'Companies'],
    },
    walletPasses: {
      providesTags: (result, _, arg) =>
        result ? [{type: 'Coupons', id: arg.walletId}, 'Coupons'] : [],
    },
    generateQrCodes: {
      invalidatesTags: (result, _, arg) => (result ? [{type: 'Coupons', id: arg.walletId}] : []),
    },
    regenerateWalletPasses: {
      invalidatesTags: (result, _, arg) => [{type: 'Coupons', id: arg.walletId}],
    },
    deleteQrCoupons: {
      invalidatesTags: (_, err, arg) => (!err ? [{type: 'Coupons', id: arg.walletId}] : []),
    },
    getParticipant: {
      providesTags: (result) =>
        result
          ? [{type: 'Participants' as const, id: result.packet}, 'Participants']
          : ['Participants'],
    },
    uploadParticipants: {
      invalidatesTags: ['Participants'],
    },
    updateParticipant: {
      invalidatesTags: (result) =>
        result ? [{type: 'Participants' as const, id: result.packet}, 'Participants'] : [],
    },
    deleteParticipant: {
      invalidatesTags: ['Participants'],
    },
    getParticipantsPage: {
      providesTags: ['Participants'],
    },
    getPass: {
      providesTags: (result) =>
        result ? [{type: 'Coupon' as const, id: result.id}, 'Coupons'] : ['Coupons'],
    },
    deletePass: {
      invalidatesTags: (r, err, arg) => (!err ? [{type: 'Coupon', id: arg.passId}, 'Coupons'] : []),
    },
    updatePass: {
      invalidatesTags: (_, err, arg) =>
        !err
          ? [
              {type: 'Coupon', id: arg.passId},
              {type: 'Coupons', id: arg.savePassRequest.walletId},
            ]
          : [],
    },
    getParticipantRemainEmail: {
      providesTags: ['RemainEmail'],
    },
    updateParticipantRemainEmail: {
      invalidatesTags: ['RemainEmail'],
    },
    getParticipantFinishedEmail: {
      providesTags: ['FinishedEmail'],
    },
    updateParticipantFinishedEmail: {
      invalidatesTags: ['FinishedEmail'],
    },
  },
})

export const {
  useGetWalletsQuery,
  useLoginMutation,
  useRegisterCustomerByQrMutation,
  useRegisterCustomerMutation,
  useUpdateOrganizationWalletMutation,
  useSaveOrganizationWalletMutation,
  useGetWalletQuery,
  useUploadFileToWalletMutation,
  useDeleteFileFromWalletMutation,
  useWalletPassesQuery,
  useGenerateQrCodesMutation,
  useExportToExcelMutation,
  useDeleteQrCouponsMutation,
  useDeletePassMutation,
  useGetTokenInfoQuery,
  useResendPassMutation,
  useUploadParticipantsMutation,
  useExportParticipantsMutation,
  useGetParticipantsPageQuery,
  useUpdateParticipantMutation,
  useGetParticipantQuery,
  useWalletSalesPersonQuery,
  useOrganizationListQuery,
  useGetOrganizationInfoQuery,
  useDeleteParticipantMutation,
  useRegenerateWalletPassesMutation,
  useGetPassQuery,
  useUpdatePassMutation,
  useGetParticipantRemainEmailQuery,
  useUpdateParticipantRemainEmailMutation,
  useGetParticipantFinishedEmailQuery,
  useUpdateParticipantFinishedEmailMutation,
} = coreApi
