/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/companies'
        title='Companies'
        fontIcon='bi-people'
        bsTitle='Companies'
        className='py-3'
      />
      <AsideMenuItem
        to='/settings'
        title='Settings'
        fontIcon='bi-sliders'
        bsTitle='Settings'
        className='py-3'
      />
    </>
  )
}
