/* eslint-disable jsx-a11y/anchor-is-valid */
import {Autocomplete, TextField} from '@mui/material'
import {skipToken} from '@reduxjs/toolkit/query'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {CreditCard, PaymentForm} from 'react-square-web-payments-sdk'
import * as Yup from 'yup'
import {INTEGER_FIELD, REQUIRED_FIELD} from '../../../lib/constants'
import {
  useGetOrganizationInfoQuery,
  useOrganizationListQuery,
  useRegisterCustomerMutation,
  useWalletSalesPersonQuery,
} from '../../api/backendApi'

const ERRORS: Record<string, string> = {
  CVV_FAILURE: 'Card CVV incorrect',
  ADDRESS_VERIFICATION_FAILURE: 'Card postal code incorrect',
  INVALID_EXPIRATION: 'Card expiration date incorrect',
  GENERIC_DECLINE: 'Card declined number',
} as const

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is wrong')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'Must be only digits')
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|\+?([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //   'Phone number is not valid'
    // )
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  zipCode: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  organizationName: Yup.string().required(REQUIRED_FIELD),
  salesPerson: Yup.string().required(REQUIRED_FIELD),
  amount: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, INTEGER_FIELD)
    .test('test-min-value', 'Should not be less than $25', (value) => +value >= 25),
})

type FormValues = {
  email: string
  phone: string
  firstName: string
  lastName: string
  packet: string
  salesPerson: string
  organizationName: string
  organizationId: string
  zipCode: string
  amount: string
}

const initialValues: FormValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  packet: '',
  zipCode: '',
  salesPerson: '',
  organizationName: '',
  organizationId: '',
  amount: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const handleError = (message: string) => {
  const errorMessage = Object.entries(ERRORS).find(([key, value]) => message.includes(key))?.[1]

  return errorMessage ?? message
}

export function DigitalCard() {
  const [stage, setStage] = useState<'INFO' | 'PAYMENT' | 'ORGANISATION'>('ORGANISATION')
  const [loading, setLoading] = useState(false)
  const [paymentError, setPaymentError] = useState<string>()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    validateOnMount: true,
    onSubmit: () => undefined,
  })

  const [register, {isSuccess, data, isLoading: registering}] = useRegisterCustomerMutation()

  const {data: organizations} = useOrganizationListQuery({})
  const {value: organizationId} = formik.getFieldProps('organizationId')
  const {data: organization} = useGetOrganizationInfoQuery(
    organizationId ? {walletId: organizationId} : skipToken
  )

  const amounts = useMemo(() => {
    return organization?.amounts || []
  }, [organization?.amounts])

  const {data: sellers} = useWalletSalesPersonQuery(
    organizationId ? {walletId: organizationId} : skipToken
  )

  const organizationsOptions = useMemo(
    () =>
      organizations?.map((o) => ({
        label: o.name,
        id: o.id,
      })),
    [organizations]
  )

  const sellersOptions = useMemo(
    () =>
      sellers?.map(({name, packet}) => ({
        label: name,
        packet,
      })),
    [sellers]
  )

  const registerPayment = useCallback(
    (paymentToken?: string) => {
      if (!registering && paymentToken) {
        setLoading(true)

        register({
          walletId: organizationId,
          registerCustomerRequest: {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            telephone: formik.values.phone,
            email: formik.values.email,
            salesPerson: formik.values.salesPerson ?? undefined,
            packet: formik.values.packet ?? undefined,
            zipCode: formik.values.zipCode,
            paymentToken,
            amount: +(formik.values.amount || 0),
          },
        })
          .unwrap()
          .then(() => {
            console.log('Registered')
          })
          .catch((e) => {
            console.log('Register error')

            setPaymentError(
              'data' in e && 'message' in e.data && typeof e.data.message == 'string'
                ? handleError(e.data.message)
                : 'Something wrong'
            )
          })
          .finally(() => setLoading(false))
      }
    },
    [
      formik.values.amount,
      formik.values.email,
      formik.values.firstName,
      formik.values.lastName,
      formik.values.packet,
      formik.values.phone,
      formik.values.salesPerson,
      formik.values.zipCode,
      organizationId,
      register,
      registering,
    ]
  )

  return isSuccess && data ? (
    <div className='col'>
      {organization?.logoImageUrl && (
        <div className='mb-4 d-flex justify-content-center'>
          <img width='200px' src={organization.logoImageUrl} alt={organization.name} />
        </div>
      )}
      <div className='text-center mb-11'>
        <h3 className='text-dark fw-bolder'>{data.successMessage}</h3>
      </div>
      <div className='text-center mb-11'>
        <h3 className='text-dark fw-bolder'>
          Thank you for your support and donation. Your payment has been received and you are now
          able to download your digital savings pass. Please look for a confirmation email with the
          link to download your pass to your Apple or Google wallet. If you do not see the email in
          your inbox, please look in your SPAM folder as well. Make sure you click on the link from
          your phone and not a computer
        </h3>
      </div>
      <div className='mb-4 mt-10 d-flex justify-content-center'>
        <img width='200px' src='/media/logos/logo.png' alt='' />
      </div>
    </div>
  ) : stage === 'ORGANISATION' || !organization ? (
    <div className='d-flex flex-column'>
      <div className='fv-row mb-3'>
        <div className='small mb-10'>
          Thank you for your interest in supporting the youth in your community. From the dropdown
          below, please select which organization you are supporting.
        </div>

        <Autocomplete
          sx={{
            '& .MuiInputBase-root': {
              p: '0.775rem 43px 0.775rem 1rem',
              borderRadius: '0.475rem',
            },

            '& .MuiOutlinedInput-root .MuiInputBase-input': {
              p: 0,
              fontSize: '1.1rem',
              fontWeight: 500,
              lineHeight: 1.5,
              height: 'unset',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              ...(formik.touched.organizationName ? {right: 32} : {}),
            },
            '& .MuiOutlinedInput-root': {
              ...(formik.touched.organizationName ? {pr: '70px !important'} : {}),
            },
          }}
          disablePortal
          isOptionEqualToValue={(option, value) => {
            return option.label === value
          }}
          options={organizationsOptions ?? []}
          {...formik.getFieldProps('organizationName')}
          onChange={(e, selectedObject) => {
            if (selectedObject !== null) {
              if (organizationId !== selectedObject.id) {
                formik.setFieldValue('organizationName', selectedObject.label)
                formik.setFieldValue('organizationId', selectedObject.id)
                formik.setFieldValue('salesPerson', '')
                formik.setFieldValue('packet', '')
              }
              setStage('INFO')
            } else {
              formik.setFieldValue('organizationName', '')
              formik.setFieldValue('organizationId', '')
              formik.setFieldValue('salesPerson', '')
              formik.setFieldValue('packet', '')
            }

            formik
              .setFieldTouched('organizationName')
              .finally(() => formik.validateField('organizationName'))
          }}
          renderInput={({InputProps, ...params}) => (
            <TextField
              {...params}
              InputProps={{
                ...InputProps,
                className: clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.organizationName && formik.errors.organizationName,
                  },
                  {
                    'is-valid': formik.touched.organizationName && !formik.errors.organizationName,
                  }
                ),
              }}
              type='text'
              placeholder='Organization Name'
            />
          )}
        />

        {formik.touched.organizationName && formik.errors.organizationName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.organizationName}</span>
            </div>
          </div>
        )}
        <div className='mb-4 mt-10 d-flex justify-content-center'>
          <img width='200px' src='/media/logos/logo.png' alt='' />
        </div>
      </div>
    </div>
  ) : stage === 'INFO' ? (
    <form
      className={`form w-100 ${stage === 'INFO' ? '' : 'd-none'}`}
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <Link
        style={{fontSize: 16, marginBottom: '21px', display: 'flex'}}
        to=''
        onClick={() => {
          setStage('ORGANISATION')
          setPaymentError(undefined)
        }}
      >
        {'<< Back'}
      </Link>
      {/* begin::Heading */}

      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {organization?.logoImageUrl && (
        <div className='mb-4 d-flex justify-content-center'>
          <img width='200px' src={organization.logoImageUrl} alt={organization.name} />
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>First Name</label>
        <input
          placeholder='First Name'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.firstName && formik.errors.firstName},
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
          type='name'
          name='firstName'
          autoComplete='off'
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.firstName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Last Name</label>
        <input
          placeholder='Last Name'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.lastName && formik.errors.lastName},
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
          type='name'
          name='lastName'
          autoComplete='off'
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.lastName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Phone</label>
        <input
          type='phone'
          placeholder='Phone'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.phone && formik.errors.phone,
            },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Zip Code</label>
        <input
          type='text'
          placeholder='Zip Code'
          autoComplete='off'
          {...formik.getFieldProps('zipCode')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.zipCode && formik.errors.zipCode,
            },
            {
              'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
            }
          )}
        />
        {formik.touched.zipCode && formik.errors.zipCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.zipCode}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
          Participants Name
        </label>
        <Autocomplete
          sx={{
            '& .MuiInputBase-root': {
              p: '0.775rem 43px 0.775rem 1rem',
              borderRadius: '0.475rem',
            },

            '& .MuiOutlinedInput-root .MuiInputBase-input': {
              p: 0,
              fontSize: '1.1rem',
              fontWeight: 500,
              lineHeight: 1.5,
              height: 'unset',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              ...(formik.touched.salesPerson ? {right: 32} : {}),
            },
            '& .MuiOutlinedInput-root': {
              ...(formik.touched.salesPerson ? {pr: '70px !important'} : {}),
            },
          }}
          disablePortal
          isOptionEqualToValue={(option, value) => {
            return option.label === value
          }}
          options={sellersOptions ?? []}
          {...formik.getFieldProps('salesPerson')}
          onChange={(e, selectedObject) => {
            if (selectedObject !== null) {
              formik.setFieldValue('salesPerson', selectedObject.label)
              formik.setFieldValue('packet', selectedObject.packet)
            } else {
              formik.setFieldValue('salesPerson', '')
              formik.setFieldValue('packet', '')
            }

            formik.setFieldTouched('salesPerson').finally(() => formik.validateField('salesPerson'))
          }}
          renderInput={({InputProps, ...params}) => (
            <TextField
              {...params}
              InputProps={{
                ...InputProps,
                className: clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.salesPerson && formik.errors.salesPerson,
                  },
                  {
                    'is-valid': formik.touched.salesPerson && !formik.errors.salesPerson,
                  }
                ),
              }}
              type='text'
              placeholder='Participants Name'
            />
          )}
        />

        {formik.touched.salesPerson && formik.errors.salesPerson && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.salesPerson}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Amount</label>
        <Autocomplete
          sx={{
            '& .MuiInputBase-root': {
              p: '0.775rem 43px 0.775rem 1rem',
              borderRadius: '0.475rem',
            },

            '& .MuiOutlinedInput-root .MuiInputBase-input': {
              p: 0,
              fontSize: '1.1rem',
              fontWeight: 500,
              lineHeight: 1.5,
              height: 'unset',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              ...(formik.touched.amount ? {right: 32} : {}),
            },
            '& .MuiOutlinedInput-root': {
              ...(formik.touched.amount ? {pr: '70px !important'} : {}),
            },
          }}
          disablePortal
          freeSolo
          autoSelect
          // isOptionEqualToValue={(option, value) => {
          //   return option.label === value
          // }}
          options={amounts.map((a) => String(a))}
          {...formik.getFieldProps('amount')}
          onChange={(e, selectedObject) => {
            formik.setFieldValue('amount', selectedObject)
            formik.setFieldTouched('amount').finally(() => formik.validateField('amount'))
          }}
          // onInput={(e) => {
          //   formik.setFieldValue('amount', e.target.value)
          //   // formik.setFieldTouched('amount').finally(() => formik.validateField('amount'))
          // }}
          renderInput={({InputProps, ...params}) => (
            <TextField
              {...params}
              InputProps={{
                ...InputProps,
                className: clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.amount && formik.errors.amount,
                  },
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                ),
              }}
              type='text'
              placeholder='Amount'
            />
          )}
        />
        {formik.touched.amount && formik.errors.amount && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.amount}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-10 gap-3'>
        <button
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={() => {
            formik
              .validateForm()
              .then((errors) => Object.keys(errors).length === 0 && setStage('PAYMENT'))
          }}
        >
          {!loading && <span className='indicator-label'>Go To Process Donation</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='mb-4 d-flex justify-content-center'>
        <img width='200px' src='/media/logos/logo.png' alt='' />
      </div>

      {/* end::Action */}
    </form>
  ) : (
    <div className={`d-flex flex-column ${stage === 'PAYMENT' ? '' : 'd-none'}`}>
      <Link
        style={{fontSize: 16, marginBottom: '21px'}}
        to=''
        onClick={() => {
          setStage('INFO')
          setPaymentError(undefined)
        }}
      >
        {'<< Back'}
      </Link>
      {organization?.logoImageUrl && (
        <div className='mb-4 d-flex justify-content-center'>
          <img width='200px' src={organization.logoImageUrl} alt={organization.name} />
        </div>
      )}

      <PaymentForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID || ''}
        cardTokenizeResponseReceived={(token, verifiedBuyer) => {
          console.log('token:', token)
          console.log('verifiedBuyer:', verifiedBuyer)
          registerPayment(token.token)
        }}
        locationId={process.env.REACT_APP_SQUARE_LOCATION_ID || ''}
      >
        <CreditCard
          postalCode={formik.values.zipCode}
          style={{
            input: {
              fontSize: '14.3px',
              // fontFamily: 'Inter, Helvetica',
              fontWeight: '500',
            },
          }}
          buttonProps={{
            className: 'btn btn-primary',
            isLoading: loading,
          }}
        >
          {`Total Due $${
            +(formik.values.amount || 0) +
            (+(process.env.REACT_APP_PROCESSING_FEE || 0) / 100) * +(formik.values.amount || 0)
          }`}
        </CreditCard>
      </PaymentForm>
      <div style={{marginTop: '5px'}} className='small'>
        {`Please note up to a ${
          process.env.REACT_APP_PROCESSING_FEE || 0
        }% fee will be charged to cover processing fees. This will allow the organization to maximize their earnings. Thank you for taking time to support the youth in your local community.`}
      </div>
      {paymentError ? (
        <div className='alert alert-danger' style={{marginTop: '10px'}}>
          <div className='alert-text font-weight-bold'>{paymentError}</div>
        </div>
      ) : null}
      <div className='mb-4 mt-10 d-flex justify-content-center'>
        <img width='200px' src='/media/logos/logo.png' alt='' />
      </div>
    </div>
  )
}
