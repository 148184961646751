import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem icon='profile-user' to='/companies' title='Companies' />
      <MenuItem icon='setting-4' to='/settings' title='Settings' />
    </>
  )
}
